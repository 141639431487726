


















import { Vue, Component } from 'vue-property-decorator'

const address = {
  windows:
    'https://star-deploy-1253924368.cos.ap-beijing.myqcloud.com/packages/batch-register-app_0.0.2.exe',
  macos:
    'https://star-deploy-1253924368.cos.ap-beijing.myqcloud.com/packages/batch-register-app_0.0.2.dmg'
}

@Component
export default class BatchRegisterDialog extends Vue {
  visible = false
  value = address.windows
  options = [
    { label: 'Windows', value: address.windows },
    { label: 'MacOS', value: address.macos }
  ]

  resolve: (value: string) => void = () => {}
  reject: (reason?: any) => void = () => {}

  prompt() {
    this.visible = true

    return new Promise<string>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  onConfirm() {
    this.resolve(this.value)
    this.visible = false
  }

  onCancel() {
    this.reject()
    this.visible = false
  }
}
