


























































































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import pageTitle from '@/components/common/pageTitle.vue'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'
import pagination from '@/components/common/pagination/index.vue'
import addPersonal from '@/components/common/AddPersonnel/index.vue'
import photoDialog from '@/components/common/photoDialog.vue'
import CopyText from '@/components/common/CopyText.vue'
import TagsDialog from '@/components/common/TagsDialog.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import RadioDialog from '@/components/common/RadioDialog/index.vue'
import { exportStatistic } from '@/utils/export'
import asyncPool from '@/utils/async-pool'
import InputSearch from '@/components/common/InputSearch.vue'
import { ElTable } from 'element-ui/types/table'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { variables } from '@/libs/theme'
import { tableScrollTop } from '@/utils/dom'
import BatchRegisterDialog from './BatchRegisterDialog.vue'

@Component({
  components: {
    PersonDetail,
    addPersonal,
    photoDialog,
    pagination,
    CopyText,
    TagsDialog,
    pageTitle,
    RadioDialog,
    FeatureImg,
    InputSearch,
    SecurityText,
    BatchRegisterDialog
  }
})
export default class AllPerson extends Vue {
  @Ref('tableContiner') tableContiner: HTMLDivElement
  @Ref('paginationNode') paginationNode: pagination
  @Ref('filterTable') filterTable: ElTable
  @Ref('exportDialog') exportDialog: RadioDialog
  @Ref('addPersonalNode') addPersonalNode: addPersonal
  @Ref('photoDialogNode') photoDialogNode: photoDialog
  @Ref('editTagsNode') editTagsNode: Vue
  @Ref('batchRegisterDialog') batchRegisterDialog: BatchRegisterDialog

  selectList = [
    {
      label: '姓名',
      value: '0'
    },
    {
      label: '编号',
      value: '1'
    },
    {
      label: 'ID',
      value: '2'
    }
  ]
  seachSelect = '0'
  searchName: string | null = null
  loading = false
  tableHeight = 0
  registerList = []
  allpersonalTitle = 'allpersonal'
  noNet = false
  noData = require('@/assets/images/dashboard/noData.png')
  count = 0
  showDetailDialog = false
  curPerson: AnyObj | null = null
  sels: AnyObj[] = []
  disabled = true
  defaultBtn = {
    background: variables.greyColor
  }
  filterData: AnyObj = {}
  typeRelation: AnyObj = {
    vip: 'VIP',
    black: '黑名单',
    white: '白名单'
  }
  placeholder = '请输入姓名查询'
  isRunning = false
  currentAccount = ''
  currentStatus: AnyObj = {}
  searchPar: AnyObj = { name: null }
  pageSize = 20
  offset = 0
  successColor = variables.colorPrimary
  primaryColorDisable = variables.colorDisabled
  private shadow9Color = variables.shadow9Color

  get identity() {
    const { identity } = this.filterData
    const allTagTypes = ['vip', 'white', 'black']

    if (identity) {
      const { tagIds, tagTypes } = identity

      return { tagTypes: tagIds || tagTypes ? tagTypes : allTagTypes, tagIds, strange: false }
    }

    return { tagTypes: allTagTypes, strange: false }
  }

  async created() {
    this.$nextTick(() => {
      this.tableHeight = this.tableContiner.clientHeight
    })
    this.getAllPersonalList(20, 0, this.searchName)
    this.currentAccount = localStorage.getItem('currentAccount') ?? '' // 拿到当前登陆账户的用户名
    this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) ?? '{}') // 拿到当前账户下所有的数据
    if (!this.currentStatus) {
      this.currentStatus = {}
    }
    const isRunning = this.currentStatus.isRunning
    this.isRunning = isRunning ? isRunning : false
  }
  closeBatch() {
    this.currentAccount = localStorage.getItem('currentAccount') ?? '' // 拿到当前登陆账户的用户名
    this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) ?? '{}') // 拿到当前账户下所有的数据
    if (!this.currentStatus) {
      this.currentStatus = {}
    }
    const isRunning = this.currentStatus.isRunning
    this.isRunning = isRunning ? isRunning : false
    this.refresh()
  }

  handleIdentityFilter() {
    this.offset = 0
    this.paginationNode.pageNo = 0
    this.getAllPersonalList(this.pageSize, this.offset, this.searchName)
  }

  selsChange(sels: AnyObj[]) {
    if (sels.length >= 1) {
      this.disabled = false
      this.defaultBtn.background = variables.colorPrimary
    } else {
      this.disabled = true
      this.defaultBtn.background = variables.greyColor
    }
    this.sels = sels
  }

  delGroup() {
    this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(() => {
        this.sels.map(async item => {
          const resp = await this.$api.base.getPersonsDelete(item.id)
          if (resp.status === 204) {
            this.message('删除成功!', 'success')
            this.refresh()
          }
        })
      })
      .catch(() => {})
  }

  handleRegister(obj: AnyObj) {
    ;(this.$refs.addPersonalNode as Vue).open('detailAdd', obj)
  }

  handleCurrentChange(row: Vue) {
    this.$refs.filterTable = row
  }

  selectType(val: string) {
    this.seachSelect = val
    switch (val) {
      case '0':
        this.placeholder = '请输入姓名查询'
        break
      case '1':
        this.placeholder = '请输入编号查询'
        break
      case '2':
        this.placeholder = '请输入ID查询'
    }
  }

  watchInput(val: string) {
    if (val === '') {
      this.searchName = null
      this.refresh()
    }
  }

  async searchDeviceList(val: string) {
    if (val === null || val.match(/^[ ]*$/)) {
      this.searchName = null
      return false
    } else {
      this.searchName = val
      this.paginationNode.pageNo = 0
      await this.getAllPersonalList(this.pageSize, 0, this.searchName)
    }
  }

  async getAllPersonalList(pageSize = 20, offset = 0, searchName: string | null) {
    const name = searchName ? searchName.replace(/(^\s*)|(\s*$)/g, '') : null
    this.loading = true
    try {
      const params = { limit: pageSize, offset: offset, identity: this.identity }
      const newParams: AnyObj = {}
      const seachVal = parseInt(this.seachSelect)
      switch (seachVal) {
        case 0:
          if (name !== null && name.split(' ').length > 1) {
            newParams.names = name.split(' ').filter(i => i !== '')
          } else {
            newParams.name = name
          }
          break
        case 1:
          newParams.externalId = name
          break
        case 2:
          newParams.personIds = name != null ? new Array(name) : []
      }
      this.searchPar = newParams
      Object.assign(params, newParams)
      const resp = await this.$api.base.getAllPersonsList(params)

      if (resp.data && resp.data.data) {
        this.registerList = resp.data.data
        this.paginationNode.init({ total: resp.data.count })
        tableScrollTop()
      }

      if (seachVal === 2 && resp.data && resp.data.code === 1002) {
        this.registerList = []
        this.paginationNode.init({ total: 0 })
      }
      this.loading = false
    } catch (error) {
      this.loading = false
      this.noNet = true
    }
  }
  scrollTop() {
    const wrapper = document.getElementsByClassName('el-table__body-wrapper')[0]
    if (wrapper) wrapper.scrollTop = 0
  }
  async refresh() {
    this.paginationNode.pageNo = 0
    await this.getAllPersonalList(this.pageSize, 0, this.searchName)
  }

  pageChange(data: AnyObj) {
    this.pageSize = data.pageSize
    this.offset = (data.pageNo - 1) * data.pageSize
    this.getAllPersonalList(this.pageSize, this.offset, this.searchName)
  }

  message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }

  showExportDialog() {
    this.exportDialog.show()
  }

  async exportResult(exportAll = true) {
    let params: AnyObj = { identity: this.identity }

    if (!exportAll) {
      if (this.sels.length === 0) return
      params = { ...params, personIds: this.sels.map(i => i.id) }
    } else {
      Object.assign(params, this.searchPar)
    }
    const res = await this.$api.base.personsExport(params)
    exportStatistic(res.data, 'csv', 'register_detail.csv')
  }

  async clickDeleteAll() {
    if (this.sels.length) {
      this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: 'message-cancel-btn',
        loading: true,
        onConfirm: async () => {
          this.loading = true
          await asyncPool(this.sels, ({ id }: AnyObj) => this.$api.base.getPersonsDelete(id))
          await this.refresh()
          this.loading = false
        }
      }).catch(() => false)
    }
  }

  async clickAdd() {
    this.addPersonalNode.open('add')
  }

  async clickBatchRegister() {
    const value = await this.batchRegisterDialog.prompt()

    open(value, '_blank')
  }

  async clickEdit(row: AnyObj) {
    const res = await this.$api.base.getPersonSingle(row.id)
    this.addPersonalNode.open('edit', res.data.data)
  }

  intoDeatail(row: AnyObj) {
    this.curPerson = { ...row }
    this.showDetailDialog = true
  }

  clickPhotoManage(row: AnyObj) {
    this.photoDialogNode.open(row)
  }

  async clickDelete({ id }: AnyObj, index: number, rows: AnyObj[]) {
    this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn',
      loading: true,
      onConfirm: async () => {
        const resp = await this.$api.base.getPersonsDelete(id)
        if (resp.status === 204) {
          rows.splice(index, 1)
          this.message('删除成功!', 'success')
          this.refresh()
        }
      }
    }).catch(() => {})
  }

  getAlreadyPeron(row: AnyObj) {
    this.curPerson = { ...row }
    this.showDetailDialog = true
  }

  clickEditTgs(row: AnyObj) {
    this.editTagsNode.open('editTags', row)
  }
}
